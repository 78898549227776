/* Imports Montserrat font */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Inter:400,500,600,700");

html,
body {
  min-height: 100vh;
  max-width: 100%;

  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  outline: none;
}

input,
button {
  outline: none;
  background: none;
  border: none;
}

input {
  background: none !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

#root,
#root > div {
  min-height: 100vh;
  max-width: 100%;
}

#root > div {
  display: flex;
  flex-direction: column;
}

@media screen and (max-height: 720px) {
  .confirm-alert-overlay {
    z-index: 12000 !important;
  }
}
